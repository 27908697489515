import React, { useState, useEffect, Fragment } from "react";
import ReactDOM from 'react-dom';
import view from '../featured/featured.module.scss';
import './reportpopupstyle.module.scss';

const ReportPopupSRPRevamp = ({ vin, featured }) => {
    const [showPopup, setShowPopup] = useState(false);
    const reportUrl = `https://rechargeddata.mxssolutions.com/vehicle/${vin}/`;

    // Handle closing the popup when clicking outside the iframe container
    const handleClickOutside = (event) => {
        const overlay = document.getElementById('popupReportOverlay');
        const container = document.getElementById(`iframeReportContainer-${vin}`);

        // Check if the click is outside both the overlay and the iframe container
        if (overlay && !container.contains(event.target)) {
            event.preventDefault();
            event.stopPropagation();
            setShowPopup(false);
        }
    };

    useEffect(() => {
        // Add event listener when popup is shown
        if (showPopup) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Clean up event listener when popup is hidden or component is unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showPopup]);

    return (
        <Fragment>
            {/* Open Report Button */}
            <div href="#" id="reportOpenIframe" style={{ height: "43px" }} onClick={(event) => { event.preventDefault(); event.stopPropagation(); setShowPopup(true); }}>
                <img id={view['reportImg']} src="https://media.dealermasters.com/Recharged/icons/RechargedScoreTransparentSmall.webp" alt="view the recharged report" />
            </div>

            {/* Popup overlay */}
            {(showPopup && featured) ?
                ReactDOM.createPortal(
                    <div id="popupReportOverlay">
                        <div id={`iframeReportContainer-${vin}`} onClick={(e) => e.stopPropagation()}>
                            <span id={`reportCloseButton-${vin}`} onClick={(event) => { event.preventDefault(); event.stopPropagation(); setShowPopup(false); }}>X</span>
                            <iframe src={reportUrl} width="100%" height="100%" frameBorder="0"></iframe>
                        </div>
                    </div>, document.getElementById('report-portal-root')
                )
            : showPopup && !featured ?
                <div id="popupReportOverlay">
                    <div id={`iframeReportContainer-${vin}`} onClick={(e) => e.stopPropagation()}>
                        <span id={`reportCloseButton-${vin}`} onClick={(event) => { event.preventDefault(); event.stopPropagation(); setShowPopup(false); }}>X</span>
                        <iframe src={reportUrl} width="100%" height="100%" frameBorder="0"></iframe>
                    </div>
                </div>
            : null
            }
        </Fragment>
    );
}

export default ReportPopupSRPRevamp;